import React, { FC } from 'react';

import { logger, LOG } from '@lib/logger';
import { ProductT } from '@lib/types';

import {
  ImageBanner,
  ChoosePackage,
  CoverageListing,
  Contact,
  OutageForm,
  IconCardList,
  ImageCard,
  ImageCardList,
  Accordion,
  LinkLists,
  Media,
  PackagesPromo,
  RegisterInterestForm,
  Suggestions,
  Test,
  TrustBox,
  SpeedTest,
  Hero,
  VideoPlayer,
  TextElement,
  TextElementList,
  ActionCardList,
  StepsInfo,
  CheckAvailability,
  LateralInfoSection,
  TrustPilotBox,
  NeedAHand,
  HeroWithCards,
  LargeInfoCard,
  TwoColumnText,
  FullLengthCardBlock,
  VideoRelay,
  BasicImage,
  HeroCurvedColumns,
} from './components';
import { VulnerabilityProcessForm } from './components/VulnerabilityProcessForm/VulnerabilityProcessForm';
import { PromoOfferBlock } from './components/PromoOfferBlock/PromoOfferBlock';
import { CoverageSingleHero } from './components/CoverageSingleHero/CoverageSingleHero';
import { EverydayHero } from './components/EverydayHero/EverydayHero';
import { EverdayFibreForm } from './components/EverdayFibreForm/EverdayFibreForm';
import { BenefitsCard } from './components/BenefitsCard/BenefitsCard';
import { TickList } from './components/TickList/TickList';

export const rowItem: {
  [key: string]: FC<any>;
} = {
  imageBanner: ImageBanner,
  choosePackage: ChoosePackage,
  contact: Contact,
  outageForm: OutageForm,
  coverageListing: CoverageListing,
  faqs: Accordion,
  hero: Hero,
  iconCardList: IconCardList,
  imageCard: ImageCard,
  imageCardList: ImageCardList,
  infoSection: Media,
  linkLists: LinkLists,
  packagesPromo: PackagesPromo,
  registerInterestForm: RegisterInterestForm,
  reviews: TrustBox,
  speedTest: SpeedTest,
  suggestions: Suggestions,
  test: Test,
  videoPlayer: VideoPlayer,
  textElement: TextElement,
  textElementList: TextElementList,
  actionCardList: ActionCardList,
  stepsInfo: StepsInfo,
  checkAvailability: CheckAvailability,
  lateralInfoSection: LateralInfoSection,
  trustPilotBox: TrustPilotBox,
  needAHand: NeedAHand,
  heroWithCards: HeroWithCards,
  largeInfoCard: LargeInfoCard,
  vulnerabilityProcess: VulnerabilityProcessForm,
  twoColumnText: TwoColumnText,
  fullLengthCardBlock: FullLengthCardBlock,
  videoRelay: VideoRelay,
  basicImage: BasicImage,
  heroCurvedColumns: HeroCurvedColumns,
  coverageSingleHero: CoverageSingleHero,
  promoOfferBlock: PromoOfferBlock,
  everydayHero: EverydayHero,
  everydayForm: EverdayFibreForm,
  benefitsCard: BenefitsCard,
  tickList: TickList,
};

export type CreateRowT = {
  type: typeof rowItem | any;
  attributes: any;
  products?: ProductT[];
};

/**
 * Builder method for creating Row components for the web pages.
 * It wraps a constructor for different types of row items and returns instances of the objects via
 * a simple API.
 *
 * @param {string} type The type of the row component
 * @param {any} attributes The props of the row
 * @param {ProductT[]} products The products object from the Product API
 * @returns {JSX.Element} The built Row component to render.
 */
export const createRow: FC<CreateRowT> = ({ type, attributes, products }): JSX.Element | null => {
  try {
    const Row = rowItem[type];

    if (!attributes?.display) return null;

    if (!Row)
      throw new TypeError(
        'EROWFACTORY: could not parse row component, check if row type matches the mappings',
      );

    return <Row products={products} {...attributes} />;
  } catch (err) {
    logger.warn(
      {
        component: type,
        err,
      },
      LOG.ERROR.FACTORY.ROW.CREATE_ROW,
    );

    return null;
  }
};

export default createRow;
