import React, { FC } from 'react';
import { StepsInfoT } from './types';
import { StepsInfo as Organism } from '@components/Organisms';

export const StepsInfo: FC<StepsInfoT> = ({ display, title, textButton, infoSteps, theme }) => {
  return (
    <Organism
      display={display}
      title={title}
      textButton={textButton}
      infoSteps={infoSteps}
      theme={theme}
    />
  );
};
